import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import Content from "../../components/content"
import SEO from "../../components/seo"

const TermsPage = ({data}) => {
    let meta = data.graphAPI.contentTermsOfUse.meta
    let content = data.graphAPI.contentTermsOfUse.content
    return (
        <Layout>
            <SEO title={meta.title} />
            <div className="main">
                <Header />
                <div className="main-content-container">
                <div className="main-content">
                    <div className="title-section">
                        <h1 className="title is-size-1">{meta.title}</h1>
                    </div>
                    <Content content={content} />
                </div>
                <Footer />
                </div>
            </div>
        </Layout>
    )
}

export default TermsPage

export const query = graphql`
    query termsPageById {
        graphAPI {
            contentTermsOfUse {
                meta {
                    id
                    title
                    description
                }
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }
                }
            }
        } 
    }
`


